











































import { FNB_OWNER } from '@/common';
import BaseComponent from '@/core/base.component';
import { FirstQuestionDto, Given } from '@/dto';
import QuestionService from '@/services/question.service';
import { Component, Prop } from 'vue-property-decorator';
@Component({
  name: 'KBCategory',
})
export default class KBCategory extends BaseComponent {
  @Prop() readonly fnbOwnerStatus: FNB_OWNER;
  private givens: Given[] = [];
  private question = '';
  private firstQuestionDto = new FirstQuestionDto();

  onBtnClick(given: Given) {
    this.$emit('progressUp');
    this.$emit('next', {
      selectedKbMediumCategory: given.givenDetails.value,
    });
  }
  onPreviousBtn() {
    this.$emit('previous');
    this.$emit('progressDown');
  }

  mounted() {
    this.$emit('loading', true);
    this.firstQuestionDto.userType = this.fnbOwnerStatus;
    QuestionService.getKBCategoryQuestion(this.firstQuestionDto).subscribe(
      res => {
        if (res) {
          this.$emit('loading', false);
          this.givens = res.data.givens;
          this.question = res.data.question;
        }
      },
    );
  }
}
