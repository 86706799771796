



































import BaseComponent from '@/core/base.component';

import { Component } from 'vue-property-decorator';
@Component({
  name: 'Complete',
})
export default class Complete extends BaseComponent {
  restart() {
    this.$root.$emit('restart');
  }
}
