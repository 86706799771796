

































import { FNB_OWNER } from '@/common';
import BaseComponent from '@/core/base.component';
import { Component, Watch } from 'vue-property-decorator';
@Component({
  name: 'FnbOwnerStatus',
})
export default class FnbOwnerStatus extends BaseComponent {
  private question = '나는 현재';
  private givens = [
    {
      id: 1,
      userType: FNB_OWNER.CUR_FNB_OWNER,
      given: '음식점 사장님입니다',
    },
    {
      id: 2,
      userType: FNB_OWNER.NEW_FNB_OWNER,
      given: '창업을 생각하고 있습니다',
    },
  ];

  saveUserType(userType: FNB_OWNER) {
    if (userType === FNB_OWNER.CUR_FNB_OWNER) {
      this.$gtag.event(`user_type_${userType}`, {
        description: '기창업자로 질문 시작',
      });
    } else {
      this.$gtag.event(`user_type_${userType}`, {
        description: '신규창업자로 질문 시작',
      });
    }
    this.$emit('next', { fnbOwnerStatus: userType });
    this.$emit('progressUp');
  }
}
