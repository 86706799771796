










































import BaseComponent from '@/core/base.component';

import { Component } from 'vue-property-decorator';
@Component({
  name: 'Loading',
})
export default class Loading extends BaseComponent {
  private loadingProgress = 0;

  mounted() {
    this.loadingProgress = 0;
    const countStart = setInterval(() => {
      if (this.loadingProgress < 79) {
        this.loadingProgress += 5;
      }
    }, 20);

    const countUp = setInterval(() => {
      if (this.loadingProgress < 100) {
        this.loadingProgress++;
      }
    }, 650);

    const countEnd = setInterval(() => {
      if (this.loadingProgress > 96 && this.loadingProgress < 100) {
        this.loadingProgress++;
      }
    }, 400);
  }
}
