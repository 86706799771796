
























































































































import BaseComponent from '@/core/base.component';
import { Component, Watch } from 'vue-property-decorator';
import { VueDaumPostcode } from 'vue-daum-postcode';

import axios from 'axios';
import {
  FirstQuestionDto,
  Given,
  NextQuestionDto,
  ResultRequestDto,
  ResultResponseDto,
} from '@/dto/question';
import { use } from 'node_modules/vue/types/umd';

import FnbOwnerStatus from './components/questions/FnbOwnerStatus.vue';
import HdongCode from './components/questions/HdongCode.vue';
import KBCategory from './components/questions/KBCategory.vue';
import MultipleQuestion from './components/questions/MultipleQuestion.vue';
import Solution from './Solution.vue';
import Complete from './Complete.vue';
@Component({
  name: 'Question',
  components: {
    VueDaumPostcode,
    FnbOwnerStatus,
    HdongCode,
    KBCategory,
    MultipleQuestion,
    Solution,
    Complete,
  },
})
export default class Question extends BaseComponent {
  private resultRequestDto = new ResultRequestDto();
  private questionTotalCount: any = 11;
  private questionOrder: any = 0;
  private resultResponseDto: ResultResponseDto = null;
  private isLoading = false;
  private isLoadingResult = false;
  private previousQuestionDtoArr: NextQuestionDto[] = [];
  private questionGivenArray: any[] = [];
  private isUtmSource = false;
  private questionIndex = -1;
  private questionOrderArr = [
    { name: 'FnbOwnerStatus', header: true },
    { name: 'HdongCode', header: true },
    { name: 'KBCategory', header: true },
    { name: 'MultipleQuestion', header: true },
    { name: 'Solution', header: false },
    { name: 'Complete', header: false },
  ];
  private ipAddress = '';
  private uniqueSessionId = '';
  startQuestion() {
    this.$gtag.event('start_question_button', { description: '질문 시작' });
    this.questionIndex = 0;
  }
  //다음 컴포넌트로 이동
  onNext(obj?: any) {
    if (obj) {
      const key = Object.keys(obj)[0];
      const value = Object.values(obj)[0];
      this.$set(this.resultRequestDto, key, value);
    }
    this.questionIndex++;
  }

  onLoading(event: boolean) {
    this.isLoading = event;
  }

  created() {
    const urlQuery = location.search;
    if (urlQuery.includes('utm_source=')) {
      this.isUtmSource = true;
    } else {
      this.isUtmSource = false;
    }
  }

  mounted() {
    // this.isLoading = true;
    //get ip address
    axios.get('https://api.ipify.org?format=json').then(res => {
      // this.isLoading = false;
      this.ipAddress = this.resultRequestDto.ipAddress = res.data.ip;
      this.uniqueSessionId = this.resultRequestDto.uniqueSessionId = `${res.data.ip}-${window.navigator.userAgent}`;
    });

    // 재시작
    this.$root.$on('restart', () => {
      this.questionIndex = -1;
      this.questionOrder = 0;
      this.resultRequestDto = new ResultRequestDto();
    });
  }

  updated() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
