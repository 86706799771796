
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import BaseComponent from '@/core/base.component';
import { Component, Prop } from 'vue-property-decorator';
import ResultRevenueChart from '@/modules/_components/charts/ResultRevenueChart.vue';
import FoodCategoryRatioChart from '@/modules/_components/charts/FoodCategoryRatioChart.vue';
import {
  ConsultRequestDto,
  ResultRequestDto,
  ResultResponseDto,
} from '@/dto/question';
import authService from '@/services/auth.service';
// import toast from '../../../resources/assets/js/services/toast.js';
import QuestionService from '@/services/question.service';
import { AggregateResultResponse } from '@/dto/question/aggregate-result-response.dto';
import { SmsAuthNotificationDto } from '@/dto';
import { ProformaResponseDto } from '@/dto/question/proforma-response.dto';
import debounce from 'lodash/debounce';
import Loading from './Loading.vue';
import { DELIVERY_GRADE_TYPE } from '@/common';

@Component({
  name: 'Solution',
  components: { ResultRevenueChart, FoodCategoryRatioChart, Loading },
})
export default class Solution extends BaseComponent {
  [x: string]: any;
  $refs!: {
    tagRef: HTMLFormElement;
    revenueWrapper: HTMLFormElement;
    tooltipWrapper: HTMLFormElement;
    bottomForm: HTMLFormElement;
  };
  @Prop() readonly resultRequestDto: ResultRequestDto;
  // private result: ProformaResponseDto = null;
  // private resultRequestDto: any = null;
  private result: any = null;
  private consultRequestDto = new ConsultRequestDto();
  private isVerified = false;
  private isSMSCodeSent = false;
  private isGetCodeBtnDisabled = false;
  private time = 30;
  private smsAuthNotificationDto = new SmsAuthNotificationDto();

  private revenueCount = 0;
  private isToggleForm = false;
  private isFormVisible = false;
  private isTooltipVislble = false;

  private isOverlayVisible = false;
  private overlayId: string;
  private onlyDeliveryType: DELIVERY_GRADE_TYPE =
    DELIVERY_GRADE_TYPE.DELIVERY_ONLY;

  // 신규창업자
  private newOtherMenu: any = null;
  private swiperRecipe: any = {
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    // virtualTranslate: true,
    slidesPerView: 1,
    loop: true,
    grabCursor: false,
    allowTouchMove: false,
    speed: 700,
    autoplay: {
      delay: 3000,
      // disableOnInteraction: true,
    },
  };
  private swiperKitchen: any = {
    slidesPerView: 1,
    loop: true,
    grabCursor: false,
    allowTouchMove: false,
    speed: 1000,
    spaceBetween: 20,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
  };

  // 기창업자 메뉴
  private selectedMenu: any = null;
  private otherMenu: any = null;
  private isOtherMenuVislble = false;
  private isDetailInfoTooltipVisible = true;

  toggleId(index: number) {
    return 'item0' + index;
  }

  get isCorrectPhoneNum() {
    const phone = this.consultRequestDto.phone;
    if (phone) {
      if (
        this.consultRequestDto.phone.startsWith('010') &&
        this.consultRequestDto.phone.length === 11
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  }

  get isConsultBtnDisabled() {
    if (
      this.consultRequestDto.name &&
      this.consultRequestDto.phone &&
      this.isCorrectPhoneNum
    ) {
      return false;
    } else {
      return true;
    }
  }

  private __countDownTimer() {
    if (this.time > 0) {
      setTimeout(() => {
        this.time -= 1;
        this.__countDownTimer();
      }, 1000);
    }
  }

  get maxRevenueValue() {
    const arr = this.result.rankDataWCScore.map(
      (e: { estimatedHighestRevenue: number }) => {
        return Math.abs(e.estimatedHighestRevenue);
      },
    );
    return Math.max(...arr);
  }

  // get max revenue
  get maxRevenue() {
    const arr = this.result.rankDataWCScore.map(
      (e: { estimatedIncreasedRevenuePercentage: number }) => {
        return Math.abs(e.estimatedIncreasedRevenuePercentage);
      },
    );
    return Math.max(...arr);
  }

  // get min revenue
  get minRevenue() {
    const arr = this.result.rankDataWCScore.map(
      (e: { estimatedIncreasedRevenuePercentage: number }) => {
        return Math.abs(e.estimatedIncreasedRevenuePercentage);
      },
    );
    return Math.min(...arr);
  }

  // toggle overlay
  onToggleOverlay(overlayId: string, event: { target: HTMLElement }) {
    const overlay = document.getElementById(overlayId);
    const toggleBtn = event.target;
    toggleBtn.classList.toggle('is-active');
    if (overlay.style.display !== 'block') {
      overlay.style.display = 'block';
    } else {
      overlay.style.display = 'none';
    }
  }

  // get auth code
  getSMSCode() {
    this.smsAuthNotificationDto.phone = this.consultRequestDto.phone;
    authService.getSMSCode(this.smsAuthNotificationDto).subscribe(res => {
      if (res) {
        this.$gtag.event('get_sms_code', {
          description: '문자 인증번호 요청',
        });
        this.time = 30;
        this.isSMSCodeSent = true;
        this.isGetCodeBtnDisabled = true;
        setTimeout(() => {
          this.isGetCodeBtnDisabled = false;
        }, this.time * 1000);
        this.__countDownTimer();
        this.isSMSCodeSent = true;
      } else {
        this.$bvToast.toast('휴대폰번호를 제대로 입력해주세요', {
          variant: 'danger',
          title: 'Error',
        });
      }
    });
  }

  // checkSMSCode() {
  //   this.smsAuthNotificationDto.phone = this.consultRequestDto.phone;
  //   this.smsAuthNotificationDto.smsAuthCode = parseInt(
  //     this.consultRequestDto.smsAuthCode,
  //   );
  //   authService.checkSMSCode(this.smsAuthNotificationDto).subscribe(res => {
  //     if (res) {
  //       this.isVerified = true;
  //       this.$gtag.event('complete_sms_auth', {
  //         description: '인증번호 확인 완료',
  //       });
  //     } else {
  //       this.$bvToast.toast(
  //         '인증번호가 올바르지 않거나 유효기간이 초과했습니다',
  //         {
  //           variant: 'danger',
  //           title: 'Error',
  //         },
  //       );
  //     }
  //   });
  // }

  onConsultBtnClicked(flag: string) {
    this.consultRequestDto.proformaConsultResultId = this.result.id;
    QuestionService.postConsult(this.consultRequestDto).subscribe(res => {
      if (res) {
        // send pixel event
        this.$analytics.fbq.event('SubmitApplication');
        if (flag === 'main') {
          this.$gtag.event('complete_application_main', {
            description: '메인 폼에서 신청 완료',
          });
        } else {
          this.$gtag.event('complete_application_sticky', {
            description: '스티키 폼에서 신청 완료',
          });
        }

        this.$emit('next');
      }
    });
  }

  // hide detail info tooltip
  hideDetailInfoTooltip() {
    const target = this.$refs.tooltipWrapper;
    if (target) {
      const rect = target.getBoundingClientRect().top;
      const winHeight = window.outerHeight;
      if (rect < winHeight / 2) {
        setTimeout(() => {
          this.isDetailInfoTooltipVisible = false;
        }, 3000);
      }
    }
  }

  stickyFormVisible() {
    const target = this.$refs.bottomForm;
    if (target) {
      const rect = target.getBoundingClientRect().top;
      const winHeight = window.outerHeight;
      if (rect < winHeight - target.offsetHeight) {
        this.isFormVisible = true;
        this.$gtag.event('scrolled_to_form', {
          description: '솔루션 페이지의 form화면으로 이동함',
        });
      } else {
        this.isFormVisible = false;
      }
    }
  }

  // scroll event
  handleScroll() {
    this.hideDetailInfoTooltip();
    this.stickyFormVisible();
  }

  findResult() {
    QuestionService.getResult(this.resultRequestDto).subscribe(res => {
      if (res) {
        this.$gtag.event(`proforma_result_seen_${res.data.id}`);
        this.result = res.data;

        // selected Menu info
        if (this.result.selectedMenuRecommendation) {
          this.selectedMenu = this.result.selectedMenuRecommendation;
        }
        // other Menu info
        if (this.result.otherMenuRecommendations) {
          this.otherMenu = this.result.otherMenuRecommendations.slice(0, 2);
        }

        // new owner other menu
        if (this.result.rankDataWCScore) {
          this.newOtherMenu = this.result.rankDataWCScore.slice(1, 3);
        }

        // start countup animation of estimate revenue
        setTimeout(() => {
          this.revenueCount = this.result.estimatedRevenue;
        }, 10);
      }
    });
  }

  handleDebouncedResizing() {
    const pcDevice = 'win16|win32|win64|mac';
    const screenWith = window.innerWidth;
    let isPcDevice = false;
    if (navigator.platform) {
      if (pcDevice.indexOf(navigator.platform.toLowerCase()) < 0) {
        isPcDevice = false;
      } else {
        isPcDevice = true;
      }
    }
    if (isPcDevice) {
      if (screenWith >= 992) {
        this.isOtherMenuVislble = true;
      } else {
        this.isOtherMenuVislble = false;
      }
    }
  }

  onClickSticky() {
    this.isToggleForm = !this.isToggleForm;
    this.$gtag.event('sticky_btn_clicked', {
      description: '솔루션 화면의 스티키 버튼을 클릭함',
    });
  }
  created() {
    this.findResult();
    window.addEventListener('resize', this.handleDebouncedResizing);
  }

  mounted() {
    this.handleDebouncedResizing();
  }

  beforeDestroy() {
    window.addEventListener('resize', this.handleDebouncedResizing);
  }
}
